<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Bill of Material Document'">
        <div class="vx-row mb-6">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Bill of Material Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code ? code : 'Auto generate'" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Description</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="description" width="100%"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            :allowEmpty="false"
                            :options="optionPurpose"
                            v-model="selectedPurpose"
                            :select-label="''"
                            :deselect-label="''"
                            :label="'name'"
                            :track-by="'name'"
                            :placeholder="''"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Instruction</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="instruction" width="100%"></vs-textarea>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex items-center">
                            <label>Attachment<small style="color: red">(only: jpg, pdf, png)</small></label>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file" multiple="multiple"
                                accept=".jpg, .png, .pdf" @change="onChangeAttachment"
                            />
                        </div>
                        <vs-divider style="margin-left: 0%">
                            List Attachment
                        </vs-divider>
                    </div>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                    <td class="td vs-table--td">
                                        <template>
                                            <vx-tooltip text="Delete">
                                                <vs-button 
                                                    type="line" 
                                                    icon-pack="feather" 
                                                    icon="icon-trash"
                                                    @click.stop="handleDeleteAttachment(i)"
                                                />
                                            </vx-tooltip>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Parent</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="35%">SKU Code</th>
                        <th width="10%">Qty</th>
                        <th width="10%">Unit</th>
                        <th width="10%">Qty UOM</th>
                        <th width="15%">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="itemParent.optionItem"
                                v-model="itemParent.selected_items"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="''"
                                @input="(val) => { onChangeSku(val, 0, 'parent'); }"
                                @search-change="(val) => { handleSearchLines(val, 0, 'parent') }"
                            />
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input 
                                class="w-full" 
                                type="number" 
                                v-model="itemParent.qty"
                                @input="(val) => { onChangeQty(val, 0, 'parent'); }"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="itemParent.optionUnit"
                                v-model="itemParent.unit"
                                :select-label="''"
                                :deselect-label="''"
                                :placeholder="''"
                                @input="(val) => { onChangeUnit(val, 0, 'parent'); }"
                            />
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input 
                                class="w-full" 
                                type="number" 
                                v-model="itemParent.qty_uom"
                                readonly
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input
                                class="w-full"
                                type="number"
                                v-model="itemParent.price"
                                :min="0"
                            ></vs-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Child</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="35%">SKU Code</th>
                        <th width="10%">Qty</th>
                        <th width="10%">Unit</th>
                        <th width="10%">Qty UOM</th>
                        <th width="5%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, index) in itemLines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="tr.optionItem"
                                v-model="tr.selected_items"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="''"
                                @input="(val) => { onChangeSku(val, index, 'child'); }"
                                @search-change="(val) => { handleSearchLines(val, index, 'child'); }"
                            />
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input 
                                class="w-full" 
                                type="number" 
                                v-model="tr.qty"
                                @input="(val) => { onChangeQty(val, index, 'child'); }"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="tr.optionUnit"
                                v-model="tr.unit"
                                :select-label="''"
                                :deselect-label="''"
                                :placeholder="''"
                                @input="(val) => { onChangeUnit(val, index, 'child'); }"
                            />
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input 
                                class="w-full" 
                                type="number" 
                                v-model="tr.qty_uom"
                                readonly
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="text-align: center">
                            <div class="vx-input-group flex">
                                <vs-button 
                                    v-if="itemLines.length > 1" 
                                    @click.stop="removeRow(index)" 
                                    size="small"
                                    color="danger" 
                                    icon-pack="feather" 
                                    icon="icon-trash" 
                                    style="margin-right: 5px"
                                    title="Remove Row"
                                />
                                <vs-button 
                                    v-if="index == itemLines.length - 1" 
                                    @click.stop="addRow(index)"
                                    size="small" 
                                    color="success" 
                                    icon-pack="feather" 
                                    icon="icon-plus"
                                    title="Add Row"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit()">{{ this.id ? 'Update' : 'Submit' }}</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import vSelect from "vue-select";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    props: {},
    components: {
        vSelect,
        VueEasyLightbox
    },
    data() {
        return {
            id: 0,
            code: null,
            optionPurpose: [
                {
                    value: 0,
                    name: "SKU-ID Change",
                },
                {
                    value: 1,
                    name: "Repackaging",
                }
            ],
            selectedPurpose: null,
            description: "",
            instruction: "",
            itemParent: {
                optionItem: [],
                selected_items: null,
                sku_code: null,
                item_name: null,
                label: null,
                optionUnit: [],
                unit: null,
                qty: 0,
                qty_uom: 0,
                price: 0,
                formattedPrice: null,
            },
            itemLines: [
                {
                    id: 0,
                    optionItem: [],
                    selected_items: null,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    optionUnit: [],
                    unit: null,
                    qty: 0,
                    qty_uom: 0,
                }
            ],
            timeOuts: null,

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData();
        } else {
            this.searchSku("", 0, "parent");
            this.searchSku("", 0, "child");
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        handleBack() {
            this.$router.push({
                name: "bill-of-material",
            });
        },
        handleSpliceLines() {
            this.itemLines.splice(1)
            this.itemLines.forEach((line) => {
                line.optionItem = []
                line.selected_items = null
                line.sku_code = null
                line.item_name = null
                line.label = null
                line.optionUnit = []
                line.unit = null
                line.qty = 0
                line.qty_uom = 0
            });
        },
        handleSearchLines(val, index, type) {
            if (this.timeOuts) {
                clearTimeout(this.timeOuts)
            }

            this.timeOuts = setTimeout(this.searchSku(val, index, type), 500)
        },
        handleGetUnitOptions(skuCode, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/unit-options", {
                params: {
                    sku_code: skuCode,
                }
            })
            .then(resp => {
                if (type == "child") {
                    if (resp.code == 200) {
                        this.itemLines[index].optionUnit = resp.data.option_units
                    } else {
                        this.itemLines[index].optionUnit = []
                    }
                } else {
                    if (resp.code == 200) {
                        this.itemParent.optionUnit = resp.data.option_units
                    } else {
                        this.itemParent.optionUnit = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetUnitUom(skuCode, unit, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/unit-uom", {
                params: {
                    sku_code: skuCode,
                    unit: unit,
                }
            })
            .then(resp => {
                if (type == "child") {
                    if (resp.code == 200) {
                        this.itemLines[index].qty_uom = resp.data
                    } else {
                        this.itemLines[index].qty_uom = 0
                    }
                } else {
                    if (resp.code == 200) {
                        this.itemParent.qty_uom = resp.data
                    } else {
                        this.itemParent.qty_uom = 0
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleUpdatePriceFormat(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            let formattedValue = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2
            }).format(cleanValue);

            this.itemParent.formattedPrice = formattedValue;
        },
        getData() {
            this.$vs.loading();
            this.$http.get("api/wms/v1/item-bill-of-material/get-edit/" + this.id)
            .then(resp => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.description = resp.data.description
                    this.selectedPurpose = this.optionPurpose.find(val => {
                        return val.value == resp.data.purpose
                    })
                    this.instruction = resp.data.instruction

                    this.searchSku("", 0, "parent");
                    this.itemParent = resp.data.item_parent
                    this.handleGetUnitOptions(this.itemParent.sku_code, 0, "parent")
                    this.handleGetUnitUom(this.itemParent.sku_code, this.itemParent.unit, 0, "parent")

                    this.itemLines = resp.data.item_lines;
                    for (let i = 0; i < this.itemLines.length; i++) {
                        var skuCode = this.itemLines[i].sku_code
                        var unit = this.itemLines[i].unit
                        this.searchSku("", i, "child")
                        this.handleGetUnitOptions(skuCode, i, "child")
                        this.handleGetUnitUom(skuCode, unit, i, "child")
                    }

                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                } else {
                    console.log(resp.message)
                }
                this.$vs.loading.close();
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleSubmit() {
            if (!this.selectedPurpose) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select document's Purpose",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.selected_items) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Sku on parent's line",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.unit) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select unit on parent's line",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemParent.qty < 1 || parseFloat(this.itemParent.qty) <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Parent Quantity must greater than 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemParent.price < 1 || parseFloat(this.itemParent.price) <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Parent Price must greater than 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let item_Parent = {
                sku_code: this.itemParent.sku_code,
                unit: this.itemParent.unit,
                quantity: Number(this.itemParent.qty),
                price: Number(this.itemParent.price),
            }

            let itemLines = []
            let message = ""
            this.itemLines.forEach(val => {
                if (!val.selected_items) {
                    message = "please select item on child's line"
                    return false
                }

                if (!val.unit) {
                    message = "please select unit on child's line for item "+val.item_name
                    return false
                }

                if (val.qty < 1) {
                    message = "quantity must be greater than zero for item "+val.item_name
                    return false
                }

                itemLines.push({
                    line_id: (val.id) ? val.id : 0,
                    sku_code: val.sku_code,
                    unit: val.unit,
                    quantity: Number(val.qty),
                })
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to submit this data?",
                accept: () => {
                    let formData = new FormData();
                    formData.append("selected_purpose", this.selectedPurpose.value);
                    formData.append("description", this.description);
                    formData.append("instruction", this.instruction);
                    formData.append("itemParent", JSON.stringify(item_Parent));
                    formData.append("itemLines", JSON.stringify(itemLines));
                    if (this.fileAttachment.length > 0) {
                        for (let index = 0; index < this.fileAttachment.length; index++) {
                            formData.append("attachments[]", this.fileAttachment[index].File);
                        }
                    }

                    this.$vs.loading();
                    this.$http.post("api/wms/v1/item-bill-of-material/create-or-update/"+(this.id ? this.id : 0), formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                title: "Success",
                                text: "Document has been "+ (this.id ? 'Updated' : 'Created'),
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                            this.handleBack();
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        onChangeSku(val, index, type) {
            if (type == "child") {
                this.itemLines[index].optionUnit = []
                this.itemLines[index].unit = null
                this.itemLines[index].qty_uom = 0
                this.itemLines[index].sku_code = val.sku_code
                this.itemLines[index].item_name = val.item_name
                this.itemLines[index].label = val.label
                this.handleGetUnitOptions(val.sku_code, index, type)
            } else {
                this.itemParent.optionUnit = []
                this.itemParent.unit = null
                this.itemParent.qty_uom = 0
                this.itemParent.sku_code = val.sku_code
                this.itemParent.item_name = val.item_name
                this.itemParent.label = val.label
                this.handleGetUnitOptions(val.sku_code, 0, type)
            }
        },
        onChangeQty(val, index, type) {
            if (val < 1) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });

                if (type == "child") {
                    this.itemLines[index].qty = 0
                } else{
                    this.itemParent.qty = 0
                }
            }
        },
        onChangeUnit(val, index, type) {
            if (type == "child") {
                this.itemLines[index].qty_uom = 0
                this.handleGetUnitUom(this.itemLines[index].sku_code, val, index, type)
            } else {
                this.itemParent.qty_uom = 0
                this.handleGetUnitUom(this.itemParent.sku_code, val, 0, type)
            }
        },
        searchSku(search, index, type) {
            this.$http.get("api/wms/v1/item-bill-of-material/items", {
                params: {
                    type: type,
                    search: search,
                }
            })
            .then(resp => {
                if (type == "child") {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.itemLines[index].optionItem = resp.data.records
                        } else {
                            this.itemLines[index].optionItem = []
                        }
                    } else {
                        this.itemLines[index].optionItem = []
                    }
                } else {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.itemParent.optionItem = resp.data.records
                        } else {
                            this.itemParent.optionItem = []
                        }
                    } else {
                        this.itemParent.optionItem = []
                    }
                }
                
            })
            .catch(error => {
                console.log(error)
            });
        },
        addRow(index) {
            this.itemLines.push({
                id: 0,
                optionItem: [],
                selected_items: null,
                sku_code: null,
                item_name: null,
                label: null,
                optionUnit: [],
                unit: null,
                qty: 0,
                qty_uom: 0,
            });
            this.searchSku("", index+1, "child");
        },
        removeRow(index) {
            this.itemLines.splice(index, 1);
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleDeleteAttachment(index) {
            this.fileAttachment.splice(index, 1);
            this.images.splice(index, 1);
        },
    },
    computed: {
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>

<style>
.multiselect--above .multiselect__content-wrapper{
    bottom: auto !important;
    top: 100% !important;
}
</style>